// cSpell:ignore lightwidget allowtransparency
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from './v3/styledComponents';

const Wrapper = styled.section`
  text-align: center;
  margin-top: 60px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 15px;
    padding: 0px 15px;
  }
`;

const Title = styled.h1`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: ${colors.navy};
  margin-bottom: 40px;


  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;

const InstagramFeed = () => (
  <>
    <Helmet>
      <script src="https://cdn.lightwidget.com/widgets/lightwidget.js" />
    </Helmet>
    <Wrapper>
      <Title>Suivez-nous sur instagram @tilli.couture</Title>
      <iframe
        title="insta_frame"
        src="//lightwidget.com/widgets/f7913e637a8d565995ec55159a67704b.html"
        scrolling="no"
        // eslint-disable-next-line react/no-unknown-property
        allowtransparency="true"
        className="lightwidget-widget"
        style={{
          width: '100%',
          border: '0',
          overflow: 'hidden',
        }}
      />
    </Wrapper>
  </>
);

export default InstagramFeed;
