import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import {
  mobileThresholdPixels, Row as RowV2, Title1, Container as ContainerV2, ContainerBlock as StyledContainerBlock,
}
  from './v2/styledComponents';
import {
  Title2, Title3, Title4, Button as ButtonV3,
} from './v3/styledComponents';
import curve from '../../assets/howItWorks/greyCurve.png';
import couturier1 from '../../assets/howItWorks/couturier1.jpg';
import couturier2 from '../../assets/howItWorks/couturier2.jpg';
import reel from '../../assets/howItWorks/reel.jpg';
import hand from '../../assets/howItWorks/hand.png';
import pin from '../../assets/howItWorks/pin.png';
import zipper from '../../assets/howItWorks/zipper.png';
import layers from '../../assets/howItWorks/layers.png';

const ContainerBlock = styled(StyledContainerBlock)`
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0;
    padding: 0;
  }
`;

const Container = styled(ContainerV2)`
  padding: 100px 90px 130px;
  overflow-x: visible;
  ${({ hasCurve, innerWidth }) => hasCurve && `
  background: ${innerWidth > 1919 ? 'center' : 'top'} no-repeat;
  background-size: 100%;
  background-image: url(${curve});
  `}
  ${({ lightGrey, theme }) => lightGrey && `background-color: ${theme.colors.lightGrey}`};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    background-image: none;
    padding: 40px 20px;
  }
`;

const Row = styled(RowV2)`
  position: relative;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  padding: ${({ noPadding }) => noPadding ? '0' : '40px 30px'};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 0;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  height: 837px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 100vw;
    max-height: 400px;
    width: 100%;
    max-width: 419px;
  }
`;

const Img = styled.img`
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 100%;
    width: 100%;
  }
`;

const ImgReel = styled.img`
  position: absolute;
  bottom: -700px;
  right: 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: none;
  }
`;

const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 80px;
  margin-left: -65px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-top: -50px;
    margin-left: 0px;
  }
`;

const Icon = styled.img`
  height: 130px;
  width: 130px;
  margin: 15px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 69px;
    width: 69px;
  }
`;

const P = styled.p`
  color: ${({ theme }) => theme.colors.navy};
  ${({ center }) => center ? 'text-align: center;' : 'text-align: left;'}
  line-height: 27px;
  width: 580px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
  }
`;

const IntroP = styled.p`
  max-width: 550px;
  font-family: Libre Baskerville;
  color: ${({ theme }) => theme.colors.navy};
  font-size: 26px;
  line-height: 45px;
  margin-top: 160px;
  margin-left: 70px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
    font-size: ${({ theme }) => theme.fontSizes.m};
    line-height: 30px;
    margin-top: 18px;
    margin-left: 0;
    text-align: center;
  }
`;

const StepNumber = styled.p`
  font-family: Libre Baskerville;
  color: ${({ theme }) => theme.colors.green};
  font-weight: 700;
  font-size: 242px;
  margin: 0;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 150px;
  }
`;

const TextLink = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  margin: 12px 16px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    margin: 0;
  }
`;

const Button = styled(ButtonV3)`
  margin-top: 25px;
`;

const steps = [
  {
    title: 'La prise de rendez-vous',
    paragraphe: `Commandez vos retouches sur notre site ou sur l’application mobile Tilli.
    Selon les types de retouches nécessaires, les différentes matières à travailler et leur disponibilité,
    Tilli confie à l’un de ses couturiers professionnels votre demande.`,
    boldParagraphe: 'Vous recevrez ensuite un mail de confirmation de rendez-vous pour l’une de vos disponibilités.',
  },
  {
    title: 'Le rendez-vous avec votre Tilliste',
    paragraphe: `Le jour du rendez-vous, votre couturier arrive directement chez vous
    afin de prendre vos mesures, épingler et vous conseiller au mieux. Vous aurez toujours la
    possibilité d’ajouter ou retirer des vêtements comme bon vous semble pendant ce rendez-vous avec votre Tilliste.`,
    boldParagraphe: 'Vous ne serez débité du montant total de votre commande qu’à la suite de ce premier rendez-vous.',
  },
  {
    title: 'Le rendu de vos vêtements',
    paragraphe: `Votre Tilliste vous rapporte vos vêtements retouchés au lieu convenu en premier rendez-vous, à date et
    heure préalablement fixées. Après s’être assuré que son travail vous convient parfaitement, vous pouvez profiter de
    vos articles !`,
    boldParagraphe: `Au cas où des ajustements sont nécessaires, votre Tilliste s’en occupera avant de vous les remettre
    définitivement.`,
  },
];

const renderIntroductionBlock = (source, setSource, innerWidth) => (
  <Container hasCurve innerWidth={innerWidth}>
    <Row alignItems="flex-start" justifyContent="center">
      <Row alignItems="flex-start" justifyContent="flex-start">
        <ImgContainer>
          <Img
            src={source}
            alt="Couturier retouche express à domicile"
            onMouseEnter={() => setSource(couturier2)}
            onMouseLeave={() => setSource(couturier1)}
          />
        </ImgContainer>
        <IconsContainer>
          <Icon src={hand} alt="Retoucherie pour cintrer et raccourcir vos manteaux et vestes" />
          <Icon src={pin} alt="Atelier de retouche haut de gamme" />
          <Icon src={zipper} alt="Top retouche express" />
          <Icon src={layers} alt="Retouche express Paris" />
        </IconsContainer>
      </Row>
      <Row>
        <IntroP>
          Tilli revisite votre façon de donner une deuxième vie à vos textiles.
          <br />
          Un procédé en 3 étapes :
        </IntroP>
        <ImgReel
          src={reel}
          alt="Rendez-vous pour cintrer vos manteaux à domicile"
        />
      </Row>
    </Row>
  </Container>
);

const renderSteps = () => (
  steps.map((step, index) => (
    <Container lightGrey={index % 2 === 0} key={step.title}>
      <Row justifyContent="center">
        <StepNumber>
          #
          {index + 1}
        </StepNumber>
        <Column alignItems="flex-start">
          <Title3>{step.title}</Title3>
          <P>
            {step.paragraphe}
            {' '}
            <b>{step.boldParagraphe}</b>
          </P>
        </Column>
      </Row>
    </Container>
  ))
);

const renderContactUs = () => (
  <Container>
    <Title4>Demande spéciale</Title4>
    <Column alignItems="center" noPadding>
      <Title2>Contactez-nous</Title2>
      <P center>
        Pour toute demande
        {' '}
        <b>urgente</b>
        {' '}
        et/ou nécessitant un travail
        {' '}
        <b>sur place</b>
        , nous vous invitons à envoyer un mail
        à
        {' '}
        <b>contact@tilli.fr</b>
        . Nous ferons tout notre possible pour répondre à vos demandes exceptionnelles, selon la
        disponibilité et les expertises de nos Tillistes.
      </P>
      <Link to={routesMap.FAQPage.url}>
        <Button navy>
          <TextLink>Besoin d’aide ?</TextLink>
        </Button>
      </Link>
    </Column>
  </Container>
);

const HowItWorks = () => {
  const [source, setSource] = useState(couturier1);
  const [innerWidth, setInnerWidth] = useState(1280);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);
  return (
    <ContainerBlock>
      <Title1>Comment ça marche ?</Title1>
      {renderIntroductionBlock(source, setSource, innerWidth)}
      {renderSteps()}
      {renderContactUs()}
    </ContainerBlock>
  );
};

export default HowItWorks;
